import cn from 'classnames'
import { ChangeEventHandler, HTMLProps, ReactNode, useMemo } from 'react'
import { Tooltip } from './Tooltip'

type Props = {
    value: string
    error?: ReactNode | { current: ReactNode }
    onChange: ChangeEventHandler<HTMLInputElement>
    style?: HTMLProps<any>['style']
    className?: string
} & HTMLProps<HTMLInputElement>

let tooltipKeyId = 0

export default function Input({ className, value, error, onChange, style, ...rest }: Props) {
    const tooltipKey = useMemo(() => ++tooltipKeyId, [error])

    return (
        <div className={cn("relative", className)} style={style}>
            <Tooltip
                key={tooltipKey}
                showOnCreate
                isHidden={!error} placement="bottom-start" size='small' trigger='manual'
                type="danger" content={(error && typeof error === 'object' && 'current' in error ? error.current : error)}
            >
                <div className='absolute inset-0 z-0'></div>
            </Tooltip>
            <input
                {...rest}
                className={cn(
                    'w-full relative z-10 max-w-full bg-neutral-200 bg-opacity-50 dark:bg-opacity-100 dark:bg-neutral-800 dark:placeholder-neutral-500',
                    'outline-none border-0 rounded-2xl px-3 py-3 pr-8',
                    {
                        'bg-red-200 dark:bg-red-900 dark:bg-opacity-25 bg-opacity-25 dark:placeholder:text-red-200 dark:placeholder:opacity-50 placeholder:text-red-800 placeholder:opacity-50': !!error,
                        'opacity-50': rest.disabled
                    }
                )}
                onChange={onChange}
                value={value}
            />
        </div>
    )
}