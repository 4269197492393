import cn from 'classnames'
import { useFormik } from 'formik'
import { ReactNode, useEffect, useState } from 'react'
import { useEvent, useKeyMemo } from '../common/hooks'
import { Button } from '../ui/Button'
import NumberInput from '../ui/NumberInput'
import { Select } from '../ui/Select'
import Add from '../ui/add.svg'
import Close from '../ui/close.svg'
import styles from './style.module.css'
import { PaymentForm, PaymentRequestForm } from './types'
import { usePaymentFormDraftStore } from './state'
import { usePanelHandlers } from '../ui/Panel'
import { noRace } from '@repaya/commons/async2'
import { useFormHandlers } from '../common/form'
import { useSupportedCoins } from '../coin/hooks'
import { Coin } from '../coin/types'
import MultiCoinIcon from '../coin/MultiCoinIcon'
import { chainName, chains } from 'web3/chains'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

interface Props {
    formKey: string
    isLoading?: boolean
    onBack(): unknown
    onSubmit(): unknown
}

type Values = { [key: string]: string }

type Errors = { [key in keyof Values]: ReactNode }

function CoinOption({ coin }: { coin: Coin }) {
    return (
        <div className="inline-block whitespace-nowrap text-left w-full">
            <MultiCoinIcon coin={coin} iconClassName="inline-block m-0" className='inline-block mr-1' />
            <span className='ml-2'>
                {coin.type === 'multi' ? coin.name : coin.code}
            </span>
        </div>
    )
}

// const useCount = create<number>(() => 1)

const defaultValues: Values = {
    amount0: '',
    coin0: 'USD_MULTI_1'
}

// const useTempValues = create<Values>(() => defaultValues)

function getInitialValues(formKey: string): { values: Values, count: number, isEmpty: boolean } {
    const savedAmounts = (usePaymentFormDraftStore.getState().get(formKey).form as PaymentRequestForm | null)?.amounts
    if (!savedAmounts || Object.values(savedAmounts).length === 0) return { values: defaultValues, count: 1, isEmpty: true }

    const values: Values = {}
    let i = 0
    for (let coin in savedAmounts) {
        values[`coin${i}`] = coin
        values[`amount${i}`] = savedAmounts[coin]
        i += 1
    }

    return { values, count: i, isEmpty: false }
}

const validate = noRace(async function* validate(values: Values, isSubmitting: boolean, intl: IntlShape, isMinimumAmounts: boolean, supportedCoins?: Record<string, Coin> | null) {
    const errors: Partial<Errors> = {}

    const usedCoins = new Set<string>()
    for (let key in values) {
        const isAmount = key.startsWith('amount')
        const i = isAmount ? +key.slice(6) : +key.slice(4)
        const value = values[key]

        if (isAmount) {
            if (value) {
                if (!/^\d+(.\d+)?$/.test(value)) {
                    errors[key] = intl.formatMessage({ defaultMessage: 'Amount must be a valid number', id: 'sPA8hu' })
                    return errors
                }

                if (!isMinimumAmounts && (+value) == 0) {
                    errors[key] = intl.formatMessage({ defaultMessage: 'Amount must be a positive number', id: 'PLFQty' })
                    return errors
                }

                if (isSubmitting && !values[`coin${i}`]) {
                    errors[`coin${i}`] = intl.formatMessage({ defaultMessage: 'Coin must be selected', id: 'TY9vOk' })
                    return errors
                }
            } else {
                if (isSubmitting && values[`coin${i}`]) {
                    errors[key] = intl.formatMessage({ defaultMessage: 'Amount cannot be empty', id: 'ilnvIx' })
                    return errors
                }
            }
        } else {
            if (!value) continue

            const coin = supportedCoins![value]!
            if (!coin.isAvailable) {
                errors[key] = intl.formatMessage({ defaultMessage: 'This coin is no longer available', id: 'a+7nL8' })
                return errors
            }
            const codes = coin.type === 'multi' ? coin.data.coins : [coin.code]

            for (const code of codes) {
                if (usedCoins.has(code)) {
                    // yield* delay(1000)
                    errors[key] = intl.formatMessage({ defaultMessage: 'This coin is already selected', id: 'AhqYgg' })
                    return errors
                }

                usedCoins.add(code)
            }
        }
    }

    return errors
})

const maxCoins = 5

export default function AmountsForm({
    formKey,
    isLoading,
    onBack,
    onSubmit
}: Props) {
    const intl = useIntl()
    const isEditing = usePaymentFormDraftStore(state => state.get(formKey).linkId != null)
    const { data: supportedCoins } = useSupportedCoins(!isEditing)
    const initialState = getInitialValues(formKey)
    const [count, setCount] = useState(initialState.count)
    const isMinimumAmount = usePaymentFormDraftStore(state => state.get(formKey).form?.isMinimumAmounts)
    const panel = usePanelHandlers()

    const [wrapSubmit, resetSubmit, handleValidate] = useFormHandlers(validate, intl, !!isMinimumAmount, supportedCoins)

    const handleSubmit = useEvent((values: Values) => {
        resetSubmit()
        let form: Partial<PaymentForm> = { amounts: {} }
        for (let i = 0; i < count; i++) {
            const amount = values[`amount${i}`]
            const coin = values[`coin${i}`]
            if (amount && coin) {
                form.amounts![coin] = amount
            }
        }

        for (const coin in form.amounts) {
            if (!coin) return
            if (!/^\d+(.\d+)?$/.test(form.amounts[coin])) return
        }

        usePaymentFormDraftStore.getState().update(formKey, form)
        onSubmit()
    })

    const formik = useFormik({
        initialValues: initialState.values,
        validate: handleValidate,
        onSubmit: handleSubmit
    })

    const handleCoinChange = useKeyMemo(n => (value: string) => {
        formik.setFieldValue(`coin${n}`, value)

        if (formik.values[`amount${n}`]) return

        const coin = supportedCoins![value]!
        if (!coin.data.group) return

        for (let i = 0; i < maxCoins; i++) {
            const amount = formik.values[`amount${i}`]
            const coinCode = formik.values[`coin${i}`]
            if (!coinCode || !amount) continue

            const otherCoin = supportedCoins![coinCode]!
            if (otherCoin.data.group === coin.data.group) {
                formik.setFieldValue(`amount${n}`, amount)
                return
            }
        }
    }, [formik])

    const handleAmountChange = useKeyMemo(n => (value: string) => {
        formik.setFieldValue(`amount${n}`, value)
    }, [])

    const handleAddCoin = useEvent(() => {
        setCount(prev => Math.min(maxCoins, prev + 1))
    })

    useEffect(() => {
        panel.onAdjustContent()
    }, [count])

    const handleRemovecoin = useKeyMemo(n => () => {
        const values = { ...formik.values }
        for (let i = +n + 1; i < count + 1; i++) {
            values[`coin${i - 1}`] = values[`coin${i}`]
            values[`amount${i - 1}`] = values[`amount${i}`]
            values[`amount${i}`] = ''
            values[`coin${i}`] = ''
        }

        formik.setValues(values)
        setCount(count - 1)
    }, [count, formik])

    return (
        <div>
            <form onSubmit={wrapSubmit(formik.handleSubmit)}>
                <h2 className='mt-0 text-center mb-2'>
                    <FormattedMessage defaultMessage="Accepted coins" id="xtOnjV" />
                </h2>
                {!isMinimumAmount && (
                    <p className='opacity-50 w-8/12 mx-auto text-center mb-12'>
                        <FormattedMessage
                            defaultMessage="Select coins to accept and enter the amount of payment you want to receive. Only coins on the {chain} are supported." id="pdx1gG"
                            values={{
                                chain: chainName(chains[0])
                            }}
                        />
                    </p>
                )}
                {isMinimumAmount && (
                    <p className='opacity-50 w-10/12 mx-auto text-center mb-12'>
                        <FormattedMessage
                            defaultMessage="Select coins and enter the minimum amount of payment you want to receive. Only coins on the {chain} are supported." id="yppt4X"
                            values={{
                                chain: chainName(chains[0])
                            }}
                        />
                    </p>
                )}
                {new Array(count).fill(0).map((_, i) => (
                    <div key={i} className='flex flex-row items-stretch mb-4 group-row'>
                        <div className='w-5/12 min-w-min'>
                            <Select
                                isDisabled={isLoading}
                                className='w-full h-full'
                                placement='bottom-end'
                                error={formik.errors[`coin${i}`]}
                                onChange={handleCoinChange(i)}
                                placeholder={<div style={{ lineHeight: '32px' }}>
                                    <FormattedMessage defaultMessage="Select coin" id="EXhrde" />
                                </div>}
                                options={Object.values(supportedCoins ?? {})
                                    .filter((coin: Coin) => {
                                        return coin.isAvailable || formik.values[`coin${i}`] === coin.code
                                    })
                                    .map(coin => ({
                                        value: coin.code, label: <CoinOption coin={coin} />
                                    }))}
                                value={formik.values[`coin${i}`]}
                            />
                        </div>
                        <NumberInput
                            className='ml-2 w-7/12'
                            placeholder={isMinimumAmount
                                ? intl.formatMessage({ defaultMessage: 'Minimum amount', id: '5/fwJh' })
                                : intl.formatMessage({ defaultMessage: 'Amount', id: '/0TOL5' })
                            } error={formik.errors[`amount${i}`]}
                            min={0}
                            name={`amount${i}`}
                            value={formik.values[`amount${i}`] ?? ''}
                            onChange={handleAmountChange(i)}
                        />
                        <div className={cn('ml-2', styles['child-h-full'], {
                            'opacity-50': count == 1
                        })}>
                            <Button
                                title={count === 1 && intl.formatMessage({ defaultMessage: 'At least one payment method must be set', id: 'XNJ+SU' }) || undefined}
                                isDisabled={count === 1 || isLoading}
                                className={cn('h-full', {
                                    'transition-all duration-200 opacity-40 group-row-hover:opacity-100': count > 1,
                                })}
                                icon={Close}
                                onClick={handleRemovecoin(i)}
                            />
                        </div>
                    </div>
                ))}
                {(count < maxCoins) && (
                    <div className='flex flex-row justify-end items-center'>
                        <Button isDisabled={isLoading} onClick={handleAddCoin} icon={Add}>
                            <FormattedMessage defaultMessage="Add another coin" id="D5NQt1" />
                        </Button>
                    </div>
                )}
                <div className='mt-12 flex flex-row justify-between items-center'>
                    <Button isDisabled={isLoading} onClick={onBack} size="large">
                        <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
                    </Button>
                    <Button isDisabled={isLoading || Object.keys(formik.errors).length > 0} type="submit" color="primary" size="large">
                        {!initialState.isEmpty && <FormattedMessage defaultMessage="Save" id="jvo0vs" />}
                        {initialState.isEmpty && <FormattedMessage defaultMessage="Create" id="VzzYJk" />}
                    </Button>
                </div>
            </form>
        </div>
    )
}