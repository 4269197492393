import { useFormik, } from 'formik'
import cn from 'classnames'
import { ReactNode, useEffect, useRef } from 'react'
import { useEvent } from '../common/hooks'
import { Button } from '../ui/Button'
import Input from '../ui/Input'
import TextArea from '../ui/TextArea'
import { getIsInfoFilledIn, usePaymentFormDraftStore } from './state'
import { PaymentRequestForm } from './types'
import { delay, noRace } from '@repaya/commons/async2'
import { useFormHandlers } from '../common/form'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

interface Props {
    formKey: string
    onBack(): void
    onSubmit(): unknown
}

interface Values {
    title: string
    // address: string
    description: string | null
}

type Errors = { [key in keyof Values]: ReactNode }

const validate = noRace(async function* (values: Values, isSubmitting: boolean, intl: IntlShape) {
    let errors: Partial<Errors> = {}

    if (!values.title) {
        if (!isSubmitting) yield* delay(1000)
        errors.title = intl.formatMessage({ defaultMessage: 'Include an invoice title', id: 'ATa6BK' })
        return errors
    }

    if (values.description && values.description.length > 820) {
        errors.description = intl.formatMessage({ defaultMessage: 'Message is too long', id: 'MBjOL0' })
        return errors
    }

    // if (!values.address) {
    //     errors.address = 'Address cannot be empty'
    //     return errors
    // }

    // if (values.address && !/0x[0-9abcdefABCDEF]{40}/.test(values.address)) {
    //     errors.address = 'The address doesn\'t look like a valid address'
    //     return errors
    // }

    return errors
})

export default function InfoForm({ formKey, onBack, onSubmit }: Props) {
    const intl = useIntl()
    const [wrapSubmit, resetSubmit, handleValidate] = useFormHandlers(validate, intl)
    const handleSubmit = useEvent((values: Values) => {
        usePaymentFormDraftStore.getState().update(formKey, values)
        resetSubmit()
        onSubmit()
    })

    const descriptionRef = useRef<HTMLTextAreaElement | null>(null)
    const state = usePaymentFormDraftStore(state => state.get(formKey))

    const form = state.form as (PaymentRequestForm | null)
    const formik = useFormik({
        initialValues: {
            title: form?.title ?? '',
            description: form?.description ?? '',
        } as Values,
        validate: handleValidate,
        onSubmit: handleSubmit
    })

    useEffect(() => {
        if (state.form != null) {
            formik.setValues(state.form as PaymentRequestForm)
        }
    }, [state.form])

    const isInfoFilledIn = getIsInfoFilledIn(state.type, state.form)

    return (
        <div>
            <form onSubmit={wrapSubmit(formik.handleSubmit)}>
                <Input
                    name="title"
                    error={formik.errors['title']}
                    placeholder={intl.formatMessage({ defaultMessage: 'Title (e.g. "Invoice for a Project")', id: 'beT71t' })}
                    value={formik.values['title']}
                    onChange={formik.handleChange}
                    className="block mb-6"
                />
                <div className={cn('overflow-hidden transition-all duration-300 will-change-transform', {})}>
                    <TextArea
                        ref={descriptionRef}
                        style={{ minHeight: '8rem', maxHeight: '19rem' }}
                        error={formik.errors['description']}
                        name="description"
                        placeholder={intl.formatMessage({ defaultMessage: 'A short message for your customer or invoice recipient (optional)', id: 'ERrLL1' })}
                        value={formik.values['description'] ?? ''}
                        onChange={formik.handleChange}
                        className="block mb-2"
                    />
                </div>
                <p className='text-sm mt-0 mb-4 px-2'>
                    <a
                        className='opacity-50 hover:opacity-100'
                        target="_blank"
                        rel='noopener noreferrer'
                        href="https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax"
                    >
                        <FormattedMessage defaultMessage="Markdown" id="jr4+vD" />
                    </a> <span className='opacity-50'><FormattedMessage defaultMessage="is supported" description="Markdown is supported" id="HnszCM" /></span>
                </p>
                <div className='flex flex-row justify-between mt-12'>
                    <Button onClick={onBack} size="large">
                        <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
                    </Button>
                    <Button isDisabled={Object.keys(formik.errors).length > 0} size="large" className='px-8' color="primary" type="submit">
                        {isInfoFilledIn && <FormattedMessage defaultMessage="Save" id="jvo0vs" />}
                        {!isInfoFilledIn && <FormattedMessage defaultMessage="Next" id="9+Ddtu" />}
                    </Button>
                </div>
            </form>
        </div>
    )
}