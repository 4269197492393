import { useFormik, } from 'formik'
import cn from 'classnames'
import { ReactNode, useEffect, useRef } from 'react'
import { useEvent } from '../common/hooks'
import { Button } from '../ui/Button'
import Input from '../ui/Input'
import { getIsInfoFilledIn, usePaymentFormDraftStore } from './state'
import { IntegrationForm } from './types'
import { usePanelHandlers } from '../ui/Panel'
import { delay, noRace } from '@repaya/commons/async2'
import { useFormHandlers } from '../common/form'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

interface Props {
    formKey: string
    onBack(): void
    onSubmit(): unknown
}

interface Values {
    storeDomain: string
    // address: string
    storeName: string | null
}

type Errors = { [key in keyof Values]: ReactNode }

function getClearedStoreOrigin(input: string): string | null {
    let domain = input
    if (!domain.startsWith('http')) domain = `https://${domain}`

    try {
        const origin = new URL(domain).origin
        return origin
    } catch (e) {
        return null
    }
}

const validate = noRace(async function* (values: Values, isSubmitting: boolean, intl: IntlShape) {
    let errors: Partial<Errors> = {}

    if (!values.storeDomain) {
        if (!isSubmitting) yield* delay(1000)
        errors.storeDomain = intl.formatMessage({ defaultMessage: 'Link cannot be empty', id: 'mp6kVT' })
        return errors
    }

    const origin = getClearedStoreOrigin(values.storeDomain)
    if (!origin) {
        if (!isSubmitting) yield* delay(1000)
        errors.storeDomain = intl.formatMessage({ defaultMessage: 'The link seems to be invalid', id: 'NUrhXP' })
        return errors
    }

    return errors
})

export default function StoreForm({ formKey, onBack, onSubmit }: Props) {
    const intl = useIntl()
    const [wrapSubmit, resetSubmit, handleValidate] = useFormHandlers(validate, intl)

    const handleSubmit = useEvent((values: Values) => {
        values.storeDomain = getClearedStoreOrigin(values.storeDomain)!
        resetSubmit()
        usePaymentFormDraftStore.getState().update(formKey, values)
        onSubmit()
    })
    const state = usePaymentFormDraftStore(state => state.get(formKey))


    const form = state.form as (IntegrationForm | null)
    const formik = useFormik({
        initialValues: {
            storeDomain: form?.storeDomain ?? '',
            storeName: form?.storeName ?? ''
        } as Values,
        validate: handleValidate,
        onSubmit: handleSubmit
    })

    useEffect(() => {
        if (state.form != null) {
            formik.setValues(state.form as IntegrationForm)
        }
    }, [state.form])

    const isInfoFilled = getIsInfoFilledIn(state.type, state.form)

    return (
        <div>
            <form onSubmit={wrapSubmit(formik.handleSubmit)}>
                <Input
                    name="storeDomain"
                    error={formik.errors['storeDomain']}
                    placeholder={intl.formatMessage({ defaultMessage: "Store domain name", id: 'olA9/+' })}
                    value={formik.values['storeDomain']}
                    onChange={formik.handleChange}
                    className="block mb-6"
                />
                <div className={cn('overflow-hidden transition-all duration-300 will-change-transform', {})}>
                    <Input
                        error={formik.errors['storeName']}
                        name="storeName"
                        placeholder={intl.formatMessage({ defaultMessage: 'Name of your store (optional)', id: 'G2yW91' })}
                        value={formik.values['storeName'] ?? ''}
                        onChange={formik.handleChange}
                        className="block mb-2"
                    />
                </div>
                <div className='flex flex-row justify-between mt-12'>
                    <Button onClick={onBack} size="large">Back</Button>
                    <Button isDisabled={Object.keys(formik.errors).length > 0} size="large" className='px-8' color="primary" type="submit">
                        {isInfoFilled && <FormattedMessage defaultMessage="Save" id="jvo0vs" />}
                        {!isInfoFilled && <FormattedMessage defaultMessage="Next" id="9+Ddtu" />}
                    </Button>
                </div>
            </form>
        </div>
    )
}