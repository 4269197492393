import cn from 'classnames'
import { useEvent, useKeyMemo } from '../common/hooks'
import { Button } from '../ui/Button'
import Register from './register.svg'
import Invoice from './invoice-inverse.svg'
import PaymentMethod from './PaymentMethod'
import { usePaymentFormDraftStore, useSelectFormType } from './state'
import { PaymentFormType } from './types'
import { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Info from 'ui/info.svg'

interface Props {
    formKey: string
    onNext(): void
}

export default function ChosePaymentFormType({
    formKey,
    onNext,
}: Props) {
    const intl = useIntl()
    const [type, setType] = useState<PaymentFormType | null>(usePaymentFormDraftStore.getState().get(formKey).type)
    const draftType = usePaymentFormDraftStore(state => state.get(formKey).type)
    const isTransitioningRef = useRef(false)

    const onSelectType = useSelectFormType(formKey)

    const handleNextClick = useEvent(() => {
        onSelectType(type!)
        onNext()
    })

    const handleSelect = useKeyMemo(selected => () => {
        isTransitioningRef.current = true

        setType(selected as PaymentFormType)

        onSelectType(selected as PaymentFormType)
        onNext()
    }, [type])

    return (
        <div>
            <h2 className={cn('text-center mb-6', {
                'mt-1': (type !== null && !isTransitioningRef.current),
            })}>
                <FormattedMessage defaultMessage="Select payment method to get started" id="YyCTE3" />
            </h2>
            <div className="min-w-min max-w-max mx-auto mb-8 flex flex-row items-center justify-center border border-neutral-800 rounded-2xl px-4 py-2">
                <div className="mr-3">
                    <Info className="w-5 fill-neutral-500" />
                </div>
                <div className='opacity-50 text-left mr-6'>
                    <FormattedMessage
                        defaultMessage="Learn more about setting up your payment form" id="ClwWGn"
                        values={{
                            link: ''
                        }}
                    />
                </div>
                <Button type="link" href="https://docs.repaya.io/docs/getting-started" isExternal isNoMargin >Docs & Guides</Button>
            </div>
            <div className='flex flex-row justify-evenly items-stretch text-center mt-8'>
                <div className='w-6/12 mx-2'>
                    <PaymentMethod
                        icon={Invoice}
                        iconSize='large'
                        onClick={handleSelect('request')}
                        isActive={type === 'request'}
                        title={intl.formatMessage({ defaultMessage: "Payment request", id: '17Qg35' })}
                    >
                        <p className='mt-2 text-md opacity-50'>
                            <FormattedMessage defaultMessage="Create a crypto invoice, a donation form, or setup a deposit flow for your service" id="3bkdhk" />
                        </p>
                    </PaymentMethod>
                </div>
                <div className='w-6/12 mx-2'>
                    <PaymentMethod
                        icon={Register}
                        iconSize='large'
                        onClick={handleSelect('integration')}
                        isActive={type === 'integration'}
                        title={intl.formatMessage({ defaultMessage: "Integration", id: 'bx7Ou/' })}
                    >
                        <p className='mt-2 text-md opacity-50'>
                            <FormattedMessage defaultMessage="Set up payment processing for your store to sell multiple products or a subscription" id="wP9e7P" />
                        </p>
                    </PaymentMethod>
                </div>
            </div>
            <div className='flex flex-row items-center justify-end mt-4'>
                {(type !== null && !isTransitioningRef.current) && (
                    <Button onClick={handleNextClick} color="primary" size="large" isDisabled={type == null}>
                        {draftType == null && <FormattedMessage defaultMessage="Next" id="9+Ddtu" />}
                        {draftType != null && <FormattedMessage defaultMessage="Save" id="jvo0vs" />}
                    </Button>
                )}
            </div>
        </div>
    )
}