import cn from 'classnames'
import { SVGProps, ReactNode } from 'react'

interface PaymentMethodProps {
    isActive: boolean
    iconSize?: 'default' | 'large'
    onClick: () => void
    icon: React.ComponentType<SVGProps<SVGSVGElement>>
    title: ReactNode
    children: ReactNode
}

export default function PaymentMethod({
    isActive,
    iconSize = 'default',
    title,
    onClick,
    icon: Icon,
    children
}: PaymentMethodProps) {
    return (
        <div
            className={cn('group h-full cursor-pointer p-4 pt-3 rounded-3xl dark:hover:bg-neutral-800 hover:bg-neutral-200 hover:bg-opacity-50 transition-colors duration-200', {
                'bg-neutral-200 bg-opacity-25 dark:bg-neutral-800 dark:bg-opacity-50': isActive
            })}
            onClick={onClick}
        >
            <div className="flex flex-row items-center justify-center whitespace-nowrap my-8 ">
                <Icon className={cn("dark:fill-neutral-200 fill-neutral-700 dark:group-hover:fill-white group-hover:fill-black transition-colors duration-100", {
                    'h-12': iconSize === 'default',
                    'h-16': iconSize === 'large'
                })} />
            </div>
            <div className="">
                <div className='text-xl font-title font-bold dark:group-hover:text-white group-hover:text-black transition-colors duration-100'>
                    {title}
                </div>
                {children}
            </div>
        </div>
    )
}
