import { useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEvent, useKeyMemo } from '../common/hooks'
import { Button } from '../ui/Button'
import Calc from './calc-small.svg'
import Donate from './moneybox.svg'
import PaymentMethod from './PaymentMethod'
import { usePaymentFormDraftStore } from './state'

interface Props {
    formKey: string
    onBack(): void
    onNext(): void
}


export default function ChosePaymentMethod({
    formKey,
    onNext,
    onBack
}: Props) {
    const intl = useIntl()
    const type = usePaymentFormDraftStore(state => state.get(formKey).type!)
    const method = usePaymentFormDraftStore(state => state.get(formKey).form && 'isMinimumAmounts' in state.get(formKey).form!
        ? state.get(formKey).form!.isMinimumAmounts ? 'min-payment' : 'exact-payment'
        : null)
    const isTransitioningRef = useRef(false)

    const handleSelect = useKeyMemo(method => () => {
        const state = usePaymentFormDraftStore.getState()
        isTransitioningRef.current = true

        state.update(formKey, { isMinimumAmounts: method === 'min-payment' })

        onNext()
    }, [formKey])

    const handleNextClick = useEvent(() => {
        onNext()
    })

    return (
        <div>
            <h2 className='mt-2 text-center'>
                <FormattedMessage defaultMessage="How would you like to get paid?" id="npIGV0" />
            </h2>
            <div className='flex flex-row justify-evenly items-stretch text-center mt-8'>
                <div className='w-6/12 mx-2 ml-4'>
                    <PaymentMethod
                        icon={Calc}
                        onClick={handleSelect('exact-payment')}
                        isActive={method === 'exact-payment'}
                        title={type === 'request'
                            ? intl.formatMessage({ defaultMessage: "Exact amount", id: 'amjqni' })
                            : intl.formatMessage({ defaultMessage: "Exact product price", id: 'rqysjZ' })}
                    >
                        <p className='mt-2 text-md opacity-50'>
                            {type === 'request' && <FormattedMessage defaultMessage="Use this method if you are requesting payment of the exact amount" id="KC7WDw" />}
                            {type === 'integration' && <FormattedMessage defaultMessage="Use this method to sell products for their exact prices" id="B7PHkD" />}
                        </p>
                    </PaymentMethod>
                </div>
                <div className='w-6/12 mx-2 mr-4'>
                    <PaymentMethod
                        icon={Donate}
                        onClick={handleSelect('min-payment')}
                        isActive={method === 'min-payment'}
                        title={type === 'request'
                            ? intl.formatMessage({ defaultMessage: "Minimum amount", id: '5/fwJh' })
                            : intl.formatMessage({ defaultMessage: "Minimum product price", id: 'XmJCMr' })}
                    >
                        <p className='mt-2 text-md opacity-50'>
                            {type === 'request' && <FormattedMessage defaultMessage="Use this method instead to ask for a donation or a deposit" id="Be25cW" />}
                            {type === 'integration' && <FormattedMessage defaultMessage="Use this method instead to let your customers name their prices" id="asLlJ/" />}

                        </p>
                    </PaymentMethod>
                </div>
            </div>
            <div className='flex flex-row items-center justify-between mt-4'>
                <Button onClick={onBack} size="large">
                    <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
                </Button>
                <Button isDisabled={method == null} onClick={handleNextClick} color="primary" size="large">
                    {method != null && <FormattedMessage defaultMessage="Save" id="jvo0vs" />}
                    {method == null && <FormattedMessage defaultMessage="Next" id="9+Ddtu" />} 
                </Button>
            </div>
        </div>
    )
}