import { useMemo } from 'react'
import { useEvent } from '../common/hooks'
import InfoForm from './infoForm'
import AmountsForm from './AmountsForm'
import ChosePaymentMethod from './ChosePaymentMethod'
import { getIsAmountsFilledIn, getIsInfoFilledIn, usePaymentFormDraftStore } from './state'
import Panel, { PanelPadding, Tab, usePanel, usePanelHandlers } from '../ui/Panel'
import ChosePaymentFormType from './ChosePaymentFormType'
import StoreForm from './StoreForm'
import CoinsForm from './CoinsForm'
import SaveForm from './SaveForm'
import { IntlShape, useIntl } from 'react-intl'

interface Props {
    formKey: string
    onComplete?: () => void
}

interface FormContentProps {
    formKey: string
    onTabProgress: (tab: string) => () => void
}

function FormContent({
    formKey,
    onTabProgress
}: FormContentProps) {
    const draft = usePaymentFormDraftStore(state => state.get(formKey))

    const panel = usePanelHandlers()

    const handleTypeSelect = useEvent(() => {
        const type = usePaymentFormDraftStore.getState().get(formKey).type
        console.log('set', type, formKey, usePaymentFormDraftStore.getState().get(formKey))
        if (type === 'request') onTabProgress('info')()
        else if (type === 'integration') onTabProgress('store')()
    })

    const handleMethodSelect = useEvent(() => {
        const type = usePaymentFormDraftStore.getState().get(formKey).type

        if (type === 'request') onTabProgress('amounts')()
        else if (type === 'integration') onTabProgress('coins')()
    })

    return (
        <>
            {panel.tab !== 'preview' && (
                <PanelPadding>
                    {panel.tab === 'type' && <ChosePaymentFormType formKey={formKey} onNext={handleTypeSelect} />}
                    {panel.tab === 'info' && <InfoForm formKey={formKey} onBack={onTabProgress('type')} onSubmit={onTabProgress('payment-method')} />}
                    {panel.tab === 'store' && <StoreForm formKey={formKey} onBack={onTabProgress('type')} onSubmit={onTabProgress('payment-method')} />}
                    {panel.tab === 'payment-method' && (
                        <ChosePaymentMethod formKey={formKey} onBack={onTabProgress(draft.type === 'request' ? 'info' : 'store')} onNext={handleMethodSelect} />
                    )}
                    {panel.tab === 'amounts' && (
                        <AmountsForm
                            formKey={formKey}
                            onBack={onTabProgress('payment-method')}
                            onSubmit={onTabProgress('final')}
                        />
                    )}
                    {panel.tab === 'coins' && (
                        <CoinsForm
                            formKey={formKey}
                            onBack={onTabProgress('payment-method')}
                            onSubmit={onTabProgress('final')}
                        />
                    )}
                    {panel.tab === 'final' && (
                        <SaveForm formKey={formKey} onBack={onTabProgress(draft.type === 'request' ? 'amounts' : 'coins')} onComplete={() => { }} />
                    )}
                </PanelPadding>
            )}
        </>
    )
}

function useTabs(formKey: string, intl: IntlShape): Tab[] {
    const draft = usePaymentFormDraftStore(state => state.get(formKey))
    const isInfoFilledIn = getIsInfoFilledIn(draft.type, draft.form)
    const isMethodFilledIn = draft.form && 'isMinimumAmounts' in draft.form
    const isAmountsFilledIn = getIsAmountsFilledIn(draft.form)

    return useMemo(() => [
        draft.linkId == null ? { tab: 'type', label: draft.type != null ? intl.formatMessage({ defaultMessage: 'Method', id: 'W+1MOm' }) : null, size: 'wide' } : null,
        draft.type === 'request' ? { tab: 'info', label: intl.formatMessage({ defaultMessage: 'Info', id: 'we4Lby' }) } : null,
        draft.type === 'integration' ? { tab: 'store', label: intl.formatMessage({ defaultMessage: 'Store info', id: '26ulIc' }) } : null,
        isInfoFilledIn ? { tab: 'payment-method', label: intl.formatMessage({ defaultMessage: 'Options', id: 'NDV5Mq' }), size: 'wide' } : null,
        draft.type === 'request' && isInfoFilledIn && isMethodFilledIn ? { tab: 'amounts', label: intl.formatMessage({ defaultMessage: 'Coins', id: 'Y8heQR' }) } : null,
        draft.type === 'integration' && isInfoFilledIn && isMethodFilledIn ? { tab: 'coins', label: intl.formatMessage({ defaultMessage: 'Coins', id: 'Y8heQR' }) } : null,
        isInfoFilledIn && isMethodFilledIn && isAmountsFilledIn ? { tab: 'final', label: intl.formatMessage({ defaultMessage: 'Save', id: 'jvo0vs' }) } : null,
    ].filter(t => t != null) as Tab[], [draft, intl, isAmountsFilledIn, isMethodFilledIn, isInfoFilledIn])
}

export default function Form({ formKey }: Props) {
    const intl = useIntl()
    const tabs = useTabs(formKey, intl)
    const panel = usePanel(tabs, tabs.length > 0 ? tabs[tabs.length - 1].tab : null)
    const isEditing = usePaymentFormDraftStore(state => state.get(formKey).linkId != null)

    return (
        <Panel isLighter={!isEditing} panel={panel} hideTabs={tabs.length < 2}>
            <FormContent formKey={formKey} onTabProgress={panel.onSwitchTab} />
        </Panel>
    )
}