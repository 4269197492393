import { useFormik, } from 'formik'
import cn from 'classnames'
import { ReactNode, useState } from 'react'
import { useAsync } from '../common/hooks'
import { Button } from '../ui/Button'
import Input from '../ui/Input'
import { usePaymentFormDraftStore, useSavePaymentForm, useSigninAndSavePaymentForm } from './state'
import { useUser } from '../auth/hooks'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { delay, noRace, wait } from '@repaya/commons/async2'
import { useFormHandlers } from '../common/form'
import { useAddress } from 'web3/hooks'
import { useAccount } from 'wagmi'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

interface Props {
    formKey: string
    onBack(): void
    onComplete(): unknown
}

interface Values {
    receiver: string
    returnURL: string | null
}

type Errors = { [key in keyof Values]: ReactNode }

function getClearedReturnURL(input: string): string | null {
    let url = input
    if (!url.startsWith('http')) url = `https://${url}`

    try {
        const parsed = new URL(url)
        const ndx = parsed.hostname.indexOf('.')
        if (parsed.hostname !== 'localhost' && (ndx === -1 || ndx === parsed.hostname.length - 1)) return null
        return parsed.toString()
    } catch (e) {
        return null
    }
}

const validate = noRace(async function* (values: Values, isSubmitting: boolean, intl: IntlShape) {
    let errors: Partial<Errors> = {}

    if (!values.receiver) {
        errors.receiver = intl.formatMessage({ defaultMessage: 'Receiving address cannot be empty', id: 'n0Wiyj' })
        if (!isSubmitting) yield* delay(1000)
        return errors
    }

    if (!/0x[0-9a-fA-F]{40}/.test(values.receiver)) {
        errors.receiver = intl.formatMessage({ defaultMessage: 'Receiving address seems to be invalid', id: 'cu30ct' })
        if (!isSubmitting) yield* delay(1000)
        return errors
    }

    if (!values.returnURL) {
        return errors
    }

    const url = getClearedReturnURL(values.returnURL)
    if (!url) {
        errors.returnURL = intl.formatMessage({ defaultMessage: 'The link seems to be invalid', id: 'NUrhXP' })
        if (!isSubmitting) yield* delay(1000)
        return errors
    }

    return errors
})

interface FormProps {
    formKey: string
    onComplete: () => void
    onBack: () => void
}

function Form({ onBack, formKey }: FormProps) {
    const intl = useIntl()
    const draft = usePaymentFormDraftStore(state => state.get(formKey))
    const [user, isLoading] = useUser()
    const [onSigninAndSave] = useSigninAndSavePaymentForm(formKey)
    const [onSave] = useSavePaymentForm(formKey)
    const address = useAddress()

    const state = usePaymentFormDraftStore(state => state.get(formKey))
    const form = state.form

    const [wrapSubmit, resetSubmit, handleValidate] = useFormHandlers(validate, intl)
    const [isSaving, setSaving] = useState(false)

    const [handleSubmit] = useAsync(async function* (values: Values) {
        resetSubmit()
        setSaving(true)
        values = { ...values, returnURL: values.returnURL ? getClearedReturnURL(values.returnURL) : null }
        usePaymentFormDraftStore.getState().update(formKey, values)
        let linkId: string | null = null
        try {
            if (user) {
                linkId = yield* wait(onSave())
            } else {
                linkId = yield* wait(onSigninAndSave())
            }
        } finally {
            if (!linkId) setSaving(false)
        }
    })

    const formik = useFormik({
        initialValues: {
            receiver: form?.receiver ?? address ?? '',
            returnURL: form?.returnURL ?? '',
        } as Values,
        validate: handleValidate,
        onSubmit: handleSubmit
    })

    return (
        <form className='mt-4' onSubmit={wrapSubmit(formik.handleSubmit)}>
            <p className='opacity-50 mb-1 ml-3'>
                <FormattedMessage defaultMessage="Ethereum address to receive payments" id="i/JdZO" />
            </p>
            <Input
                name="receiver"
                error={formik.errors['receiver']}
                placeholder='0x...'
                value={formik.values['receiver']}
                onChange={formik.handleChange}
                className="block mb-6"
            />
            <p className='opacity-50 mb-1 ml-3'>
                <FormattedMessage
                    defaultMessage="Link to redirect {client} to after successful payment (optional)" id="KBOKCX"
                    values={{
                        client: draft.type === 'request'
                            ? intl.formatMessage({ defaultMessage: 'a client', id: 'MIzCO/' })
                            : intl.formatMessage({ defaultMessage: 'a customer', id: 'tTHqlO' })
                    }}
                />
            </p>
            <Input
                name="returnURL"
                error={formik.errors['returnURL']}
                placeholder='https://'
                value={formik.values['returnURL'] ?? ''}
                onChange={formik.handleChange}
                className={cn("block", {
                    'mb-0': draft.type === 'integration'
                })}
            />
            {draft.type === 'integration' && (
                <p className='opacity-50 mt-2 mb-6 ml-3'>
                    <FormattedMessage defaultMessage="Payment parameters are included as query parameters automatically on checkout" id="AhI22u" />
                </p>
            )}
            <div className='flex flex-row justify-between mt-12'>
                <Button onClick={onBack} size="large">Back</Button>
                <Button isDisabled={Object.keys(formik.errors).length > 0} isLoading={isLoading || formik.isSubmitting || isSaving} size="large" className='px-8' color="primary" type="submit">
                    <FormattedMessage
                        defaultMessage="Sign {form}" id="ayJTVx"
                        values={{
                            form: draft.type === 'request'
                                ? intl.formatMessage({ defaultMessage: 'the request', id: 'i3SaDN', description: "Sign the request" })
                                : intl.formatMessage({ defaultMessage: 'the integration form', id: 'Zo31Qp', description: "Sign the integration form" })
                        }}
                    />
                </Button>
            </div>
        </form>
    )
}

export default function SaveForm({ onBack, onComplete, formKey }: Props) {
    const intl = useIntl()
    const draftType = usePaymentFormDraftStore(state => state.get(formKey).type)
    const { isConnected } = useAccount()

    return (
        <div>
            <h2 className='mt-0 text-center mb-2'>
                <FormattedMessage defaultMessage="Signing & Saving" id="CK+Q4W" />
            </h2>
            {!isConnected && (
                <ConnectButton.Custom>{({
                    account,
                    chain,
                    openChainModal,
                    openConnectModal,
                    mounted,
                }) => {
                    const connected = mounted && !!account && !!chain
                    if (!connected) {
                        return (
                            <div className='text-center mb-8'>
                                <p className='opacity-50 w-10/12 mx-auto text-center mb-8 mt-0'>
                                    <FormattedMessage
                                        defaultMessage="Connect your wallet to sign and save {form} with your account" id="+IW4Ao"
                                        values={{
                                            form: draftType === 'request'
                                                ? intl.formatMessage({ defaultMessage: 'the invoice', id: 'ZqPMKd' })
                                                : intl.formatMessage({ defaultMessage: 'the integration form', id: '+WfgZU' })
                                        }}
                                    />
                                </p>
                                <Button size="large" color="primary" onClick={() => openConnectModal()}>
                                    <FormattedMessage defaultMessage="Connect wallet" id="VASo1/" />
                                </Button>
                            </div>
                        )
                    }

                    if (chain.unsupported) {
                        return (
                            <div className='text-center'>
                                <Button className='mt-4' color="primary" size="large" onClick={() => openChainModal()}>
                                    <FormattedMessage defaultMessage="Switch chain" id="ptNDgs" />
                                </Button>
                            </div>
                        )
                    }

                    return null
                }}</ConnectButton.Custom>
            )}
            {isConnected && <Form formKey={formKey} onBack={onBack} onComplete={onComplete} />}
        </div>
    )
}