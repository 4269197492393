import cn from 'classnames'
import { useFormik } from 'formik'
import { ReactNode, useEffect, useState } from 'react'
import { useEvent, useKeyMemo } from '../common/hooks'
import { Button } from '../ui/Button'
import { Select } from '../ui/Select'
import Add from '../ui/add.svg'
import Close from '../ui/close.svg'
import styles from './style.module.css'
import { PaymentForm, IntegrationForm } from './types'
import { usePaymentFormDraftStore } from './state'
import { usePanelHandlers } from '../ui/Panel'
import { noRace } from '@repaya/commons/async2'
import { useFormHandlers } from '../common/form'
import { useSupportedCoins } from '../coin/hooks'
import { Coin } from '../coin/types'
import MultiCoinIcon from '../coin/MultiCoinIcon'
import { chains, chainName } from 'web3/chains'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

interface Props {
    formKey: string
    isLoading?: boolean
    onBack(): unknown
    onSubmit(): unknown
}

type Values = { [key: string]: string }

type Errors = { [key in keyof Values]: ReactNode }

function CoinOption({ coin }: { coin: Coin }) {
    return (
        <div className="inline-block whitespace-nowrap text-left w-full">
            <MultiCoinIcon coin={coin} iconClassName="inline-block m-0" className='inline-block mr-1' />
            <span className='ml-2'>
                {coin.type === 'multi' ? coin.name : coin.code}
            </span>
        </div>
    )
}

const defaultValues: Values = {
    coin0: 'USD_MULTI_1'
}

function getInitialValues(formKey: string): { values: Values, count: number, isEmpty: boolean } {
    const savedCoins = (usePaymentFormDraftStore.getState().get(formKey).form as IntegrationForm | null)?.coins
    if (!savedCoins || savedCoins.length === 0) return { values: defaultValues, count: 1, isEmpty: true }

    const values: Values = {}
    let i = 0
    for (let coin of savedCoins) {
        values[`coin${i}`] = coin
        i += 1
    }

    return { values, count: i, isEmpty: false }
}

const validate = noRace(async function* (values: Values, _, intl: IntlShape, supportedCoins?: Record<string, Coin> | null) {
    const errors: Partial<Errors> = {}

    const usedCoins = new Set<string>()
    for (const key in values) {
        const value = values[key]
        if (!value) continue

        const coin = supportedCoins![value]!
        if (!coin.isAvailable) {
            errors[key] = intl.formatMessage({ defaultMessage: 'This coin is no longer available', id: 'a+7nL8' })
            return errors
        }

        const codes = coin.type === 'multi' ? coin.data.coins : [coin.code]

        for (const code of codes) {
            if (usedCoins.has(code)) {
                // yield* delay(1000)
                errors[key] = intl.formatMessage({ defaultMessage: 'This coin is already selected', id: 'AhqYgg' })
                return errors
            }

            usedCoins.add(code)
        }
    }

    return errors
})

const maxCoins = 5

export default function CoinsForm({
    formKey,
    isLoading,
    onBack,
    onSubmit
}: Props) {
    const initialState = getInitialValues(formKey)
    const isEditing = usePaymentFormDraftStore(state => state.get(formKey).linkId != null)
    const { data: supportedCoins } = useSupportedCoins(!isEditing)
    const [count, setCount] = useState(initialState.count)
    const panel = usePanelHandlers()
    const intl = useIntl()
    const [wrapSubmit, resetSubmit, handleValidate] = useFormHandlers(validate, intl, supportedCoins)

    const handleSubmit = useEvent((values: Values) => {
        const usedCoins = new Set<string>()
        let form: Partial<PaymentForm> = { coins: [] }
        for (let i = 0; i < count; i++) {
            const coin = values[`coin${i}`]
            if (!coin) continue
            if (usedCoins.has(coin)) continue

            usedCoins.add(coin)
            form.coins!.push(coin)
        }
        usePaymentFormDraftStore.getState().update(formKey, form)
        resetSubmit()
        onSubmit()
    })

    const formik = useFormik({
        initialValues: initialState.values,
        validate: handleValidate,
        onSubmit: handleSubmit
    })

    const handleCoinChange = useKeyMemo(n => (value: string) => {
        formik.setFieldValue(`coin${n}`, value)
    }, [formik])

    const handleAddCoin = useEvent(() => {
        setCount(prev => Math.min(maxCoins, prev + 1))
    })

    useEffect(() => {
        panel.onAdjustContent()
    }, [count])

    const handleRemovecoin = useKeyMemo(n => () => {
        const values = { ...formik.values }
        for (let i = +n + 1; i < count + 1; i++) {
            values[`coin${i - 1}`] = values[`coin${i}`]
            values[`coin${i}`] = ''
        }

        formik.setValues(values)
        setCount(count - 1)
    }, [count, formik])

    return (
        <div>
            <form onSubmit={wrapSubmit(formik.handleSubmit)}>
                <h2 className='mt-0 text-center mb-2'>
                    <FormattedMessage defaultMessage="Accepted coins" id="xtOnjV" />
                </h2>
                <p className='opacity-50 w-10/12 mx-auto text-center mb-12'>
                    <FormattedMessage
                        defaultMessage="Select coins to accept as payment for your products. Only coins on the {chain} are supported." id="hCmLAt"
                        values={{
                            chain: chainName(chains[0])
                        }}
                    />
                </p>
                {new Array(count).fill(0).map((_, i) => (
                    <div key={i} className='flex flex-row items-stretch mb-4 group-row'>
                        <div className='grow'>
                            <Select
                                isDisabled={isLoading}
                                className='w-full h-full'
                                error={formik.errors[`coin${i}`]}
                                placement='bottom-end'
                                onChange={handleCoinChange(i)}
                                placeholder={<div style={{ lineHeight: '32px' }}>
                                    <FormattedMessage defaultMessage="Select coin" id="EXhrde" />
                                </div>}
                                options={Object.values(supportedCoins ?? {})
                                    .filter((coin: Coin) => {
                                        return coin.isAvailable || formik.values[`coin${i}`] === coin.code
                                    })
                                    .map(coin => ({
                                        value: coin.code, label: <CoinOption coin={coin} />
                                    }))}
                                value={formik.values[`coin${i}`]}
                            />
                        </div>
                        <div className={cn('ml-2', styles['child-h-full'], {
                            'opacity-50': count == 1
                        })}>
                            <Button
                                title={count === 1 && intl.formatMessage({ defaultMessage: 'At least one payment method must be set', id: 'XNJ+SU' }) || undefined}
                                isDisabled={count === 1 || isLoading}
                                className={cn('h-full', {
                                    'transition-all duration-200 opacity-40 group-row-hover:opacity-100': count > 1,
                                })}
                                icon={Close}
                                onClick={handleRemovecoin(i)}
                            />
                        </div>
                    </div>
                ))}
                {(count < maxCoins) && (
                    <div className='flex flex-row justify-end items-center'>
                        <Button isDisabled={isLoading} onClick={handleAddCoin} icon={Add}>
                            <FormattedMessage defaultMessage="Add another coin" id="D5NQt1" />
                        </Button>
                    </div>
                )}
                <div className='mt-12 flex flex-row justify-between items-center'>
                    <Button isDisabled={isLoading} onClick={onBack} size="large">
                        <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
                    </Button>
                    <Button isDisabled={isLoading || Object.keys(formik.errors).length > 0} type="submit" color="primary" size="large">
                        {!initialState.isEmpty && <FormattedMessage defaultMessage="Save" id="jvo0vs" />}
                        {initialState.isEmpty && <FormattedMessage defaultMessage="Create" id="VzzYJk" />}
                    </Button>
                </div>
            </form>
        </div>
    )
}