import cn from 'classnames'
import { ReactNode } from 'react'
import Header from '../components/Header'
import Footer from './Footer'

interface Props {
    children?: ReactNode
}

export default function Layout({
    children
}: Props) {
    return (
        <div className='w-full h-full flex flex-col items-stretch justify-start' style={{ height: 'calc(100vh + 128px)' }}>
            <div className="grow relative">
                <Header />
                {children}
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}